import { shared, mixins } from "../styles";
import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import "./icon";
import "./spinner";
import { Toggle } from "./toggle";
import { rippleOnClick, rippleRemove } from "../lib/util";

type ButtonType = "button" | "submit" | "reset";
type ButtonState = "idle" | "loading" | "success" | "fail";
type ButtonRole = "button" | "switch" | "link";
type ButtonColor = "primary" | "secondary" | "danger";

@customElement("pl-button")
export class Button extends LitElement {
    @property({ type: String })
    type: ButtonType = "button";

    @property()
    role: ButtonRole = "button";

    @property({ reflect: true })
    state: ButtonState = "idle";

    @property()
    color: ButtonColor = "primary";

    @property({ type: Boolean })
    noTab: boolean = false;

    @property()
    label: string = "";

    @property({ type: Boolean, reflect: true })
    toggled?: boolean;

    @query("button")
    private _button!: HTMLButtonElement;

    private _stopTimeout: number;

    static styles = [
        shared,
        css`
            :host {
                display: block;
                text-align: center;
                font-weight: var(--button-font-weight);
            }

            :host([state="loading"]) {
                opacity: 0.7;
            }

            button {
                padding: var(--spacing-md) var(--spacing-lg);
                width: 100%;
                position: relative;
                background: transparent;
                border-radius: var(--radius-md);
                border-style: solid;
                border-width: var(--border-width);
                border-color: transparent;
                box-sizing: border-box;
                font-weight: inherit;
                text-align: inherit;
                --focus-outline-color: var(--button-focus-outline-color);
                color: var(--color-button-tertiary-fg);
                overflow: hidden;
                transition-property: transform, color, background, background-color, border-color, text-decoration-color, fill, stroke, opacity;
                transition-timing-function: ease;
                transition-duration: .25s;
                cursor: pointer;
            }

            :host([state="loading"]) .spinner {
                cursor: progress;
            }

            :host(.icon-only) button {
                padding: var(--spacing-sm, 6px);
                border-width: 0;
                color: inherit;
                width: 2.5em;
                height: 2.5em;
            }  

            button:focus-visible,
            :host(.focus-visible) button {
                box-shadow: var(--button-border-shadow);
            }
                
            button:active {
                color: var(--color-button-tertiary-fg);
                // transform: scaleX(0.97) scaleY(0.97);
            }

            :host([toggled]:not(.disable-toggle-styling)) button {
                background: var(--button-toggled-background, var(--color-highlight));
                color: var(--button-toggled-color, var(--color-white));
                border-width: var(--button-toggled-border-width, var(--button-border-width));
                border-style: var(--button-toggled-border-style, var(--button-border-style));
                border-color: var(--button-toggled-border-color, var(--button-border-color));
                font-weight: var(--button-toggled-weight);
                transform: scale(1.02);
            }

            :host(.transparent) button {
                background: transparent;
                border-color: transparent;
            }

            :host(.transparent) button:active {
                color: var(--color-button-tertiary-fg);
                background: transparent;
            }

            :host(.round) button {
                border-radius: 100%;
            }

            :host(.sharp) button {
                border-radius: 0;
            }

            :host([color="primary"]) button {
                background: var(--color-button-primary-bg);
                color: var(--color-button-primary-fg);
                text-shadow: var(--text-shadow);
                border-width: 1px;
                border-style: var(--button-primary-border-style);
                border-color: transparent;
                font-weight: var(--button-primary-font-weight);
                --focus-outline-color: var(--button-primary-focus-outline-color);
                position: relative;
                box-shadow: var(--button-shadow);
            }

            :host([color="primary"]) button:focus-visible,
            :host([color="primary"].focus-visible) button {
                box-shadow: var(--button-outline-shadow);
            }            

            :host([color="primary"]) button:active {
                color: var(--color-button-primary-fg);
                background: var(--color-button-primary-bg);
            }            

            :host([color="primary"]) button::before {
                content: '';
                position: absolute;
                inset: -1px;
                padding: var(--button-primary-border-width);
                border-radius: inherit;
                background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0.12) 0%,
                    rgba(255, 255, 255, 0) 100%
                );
                -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                pointer-events: none;
            }

            :host([color="primary"][toggled]:not(.disable-toggle-styling)) button {
                background: var(--button-primary-toggled-background, var(--button-toggled-background));
                color: var(--button-primary-toggled-color, var(--button-toggled-color));
                border-width: var(--button-primary-toggled-border-width, var(--button-toggled-border-width));
                border-style: var(--button-primary-toggled-border-style, var(--button-toggled-border-style));
                border-color: var(--button-primary-toggled-border-color, var(--button-toggled-border-color));
                font-weight: var(--button-primary-toggled-font-weight, var(--button-primary-font-weight));
                transform: scale(1.02);
            }

            :host([color="secondary"]) button {
                border-color: var(--color-button-secondary-border);
                background: var(--color-button-secondary-bg);
                box-shadow: var(--button-shadow);
                color: var(--color-button-secondary-fg);
            }

            :host(:not([color])) button:focus-visible,
            :host(:not([color]).focus-visible) button,
            :host([color="secondary"]) button:focus-visible,
            :host([color="secondary"].focus-visible) button {
                box-shadow: var(--button-outline-shadow);
            }

            :host([color="secondary"]) button:active {
                color: var(--color-button-secondary-fg);
                background: var(--color-button-secondary-bg);
            }

            :host([color="ghost"]) button {
                background: var(--button-ghost-background, var(--button-background));
                color: var(--button-ghost-color, var(--button-color));
                border-width: var(--button-ghost-border-width, var(--button-border-width));
                border-style: var(--button-ghost-border-style, var(--button-border-style));
                border-color: var(--button-ghost-border-color, var(--button-border-color));
                font-weight: var(--button-ghost-font-weight, var(--button-font-weight));
                --focus-outline-color: var(--button-ghost-focus-outline-color);
            }

            :host([color="ghost"][toggled]:not(.disable-toggle-styling)) button {
                background: var(--button-ghost-toggled-background, var(--button-toggled-background));
                color: var(--button-ghost-toggled-color, var(--button-toggled-color));
                border-width: var(--button-ghost-toggled-border-width, var(--button-toggled-border-width));
                border-style: var(--button-ghost-toggled-border-style, var(--button-toggled-border-style));
                border-color: var(--button-ghost-toggled-border-color, var(--button-toggled-border-color));
                font-weight: var(--button-ghost-toggled-font-weight, var(--button-ghost-font-weight));
                transform: scale(1.02);
            }

            :host([color="danger"]) button {
                background: var(--button-negative-background, var(--button-background));
                color: var(--button-negative-color, var(--button-color));
                text-shadow: var(--text-shadow);
                border-width: var(--button-negative-border-width, var(--button-border-width));
                border-style: var(--button-negative-border-style, var(--button-border-style));
                border-color: var(--button-negative-border-color, var(--button-border-color));
                font-weight: var(--button-negative-font-weight, var(--button-font-weight));
                font-weight: var(--button-negative-toggled-font-weight, var(--button-negative-font-weight));
                --focus-outline-color: var(--button-negative-focus-outline-color);
            }

            :host([color="danger"][toggled]:not(.disable-toggle-styling)) button {
                background: var(--button-negative-toggled-background, var(--button-toggled-background));
                color: var(--button-negative-toggled-color, var(--button-toggled-color));
                border-width: var(--button-negative-toggled-border-width, var(--button-toggled-border-width));
                border-style: var(--button-negative-toggled-border-style, var(--button-toggled-border-style));
                border-color: var(--button-negative-toggled-border-color, var(--button-toggled-border-color));
                transform: scale(1.02);
            }

            :host(.borderless) button {
                border: none;
            }

            :host(.slim) button {
                padding: var(--button-padding-slim, 0.5em);
            }

            :host(.skinny) button {
                padding: var(--button-padding-skinny, 0.3em);
            }

            :host(.rounded) button {
                border-radius: 2em;
            }

            :host(.not-bold) {
                font-weight: normal;
            }

            button > * {
                transition: transform 0.2s cubic-bezier(1, -0.3, 0, 1.3), opacity 0.2s;
                will-change: transform;
            }

            button > :not(.label):not(.button-ripple) {
                ${mixins.absoluteCenter()};
            }

            button.loading .label,
            button.success .label,
            button.fail .label,
            button:not(.loading) .spinner,
            button:not(.success) .icon-success,
            button:not(.fail) .icon-fail {
                opacity: 0.5;
                transform: scale(0);
            }

            button pl-icon {
                font-size: 120%;
            }

            pl-spinner {
                width: 1.5em;
                height: 1.5em;
            }

            @media (min-width: 1000px) {

            }

            @media (hover: hover) {
                button:hover {
                    color: var(--color-button-tertiary-fg-hover);
                    background: var(--color-button-tertiary-bg-hover);
                } 

                :host(.transparent) button:hover {
                    color: var(--color-button-tertiary-fg-hover);
                    background: var(--color-button-tertiary-bg-hover);
                } 

                :host([color="primary"]) button:hover {
                    color: var(--color-button-primary-fg-hover);
                    background: var(--color-button-primary-bg-hover, #6941C6);
                } 

                :host([color="secondary"]) button:hover {
                    border-color: var(--color-button-secondary-border-hover);
                    background: var(--color-button-secondary-bg-hover);
                    color: var(--color-button-secondary-fg-hover);
                }                                                            
            }            
        `,
    ];

    render() {
        const { state, noTab } = this;
        return html`
            <button
                type=${this.type}
                role="${this.role}"
                class="${state}"
                tabindex="${noTab ? "-1" : ""}"
                aria-label=${this.label}
                aria-pressed=${!!this.toggled}
            >
                <div class="centering spacing horizontal layout label"><slot></slot></div>

                <pl-spinner .active="${state == "loading"}" class="spinner"></pl-spinner>

                <pl-icon icon="check" class="icon-success"></pl-icon>

                <pl-icon icon="cancel" class="icon-fail"></pl-icon>
            </button>
        `;
    }

    static get is() {
        return "pl-button";
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("click", (e: MouseEvent) => {
            if (this.state === "loading") {
                e.stopPropagation();
                e.preventDefault();
                return;
            }

            // const ripple = document.createElement("div");
            // const rect = this._button.getBoundingClientRect();

            // const size = Math.max(this._button.clientWidth, this._button.clientHeight);
            // const x = e.clientX - rect.left - size / 2;
            // const y = e.clientY - rect.top - size / 2;
            
            // ripple.style.width = ripple.style.height = `${size}px`;
            // ripple.style.left = `${x}px`;
            // ripple.style.top = `${y}px`;
            // ripple.classList.add("button-ripple");
            
            // const clamp = (value: number, min: number, max: number) => {
            //     return Math.min(Math.max(value, min), max);
            //   };
            // const duration = clamp(0.01 * size, 0.2, size > 100 ? 0.75 : 0.5);
            // ripple.style.setProperty('--ripple-duration', `${duration}s`);

            // this._button.appendChild(ripple);
            
            // ripple.addEventListener("animationend", () => {
            //     ripple.remove();
            // });
        });
    }

    firstUpdated() {
        rippleOnClick(this._button);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        rippleRemove(this._button);
    }

    protected _toggledChanged() {
        const toggleEl = this.querySelector("pl-toggle") as Toggle;
        if (toggleEl) {
            toggleEl.active = !!this.toggled;
        }
    }

    updated(changes: Map<string, any>) {
        if (changes.has("toggled")) {
            this._toggledChanged();
        }
    }

    start() {
        clearTimeout(this._stopTimeout);
        this.state = "loading";
    }

    stop() {
        this.state = "idle";
    }

    success() {
        this.state = "success";
        this._stopTimeout = window.setTimeout(() => this.stop(), 1000);
    }

    fail() {
        this.state = "fail";
        this._stopTimeout = window.setTimeout(() => this.stop(), 1000);
    }

    focus() {
        this._button.focus();
    }
}

