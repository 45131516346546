import { Storable } from "./storage";

export enum PaymentMethod {
  Alipay = "alipay",
  Weixinpay = "weixinpay",
}

export enum BillingAction {
    UpdateSubscription = "update_subscription",
    CancelSubscription = "cancel_subscription",
    ReactivateSubscription = "reactivate_subscription",
    UpdateBillingInfo = "update_billing_info",
    AddPaymentMethod = "add_payment_method",
    Subscribe = "subscribe",
    PurchaseSeats = "purchase_seats",
    Update = "update",
    Upgrade = "upgrade",    
}

export enum Tier {
  Free = "free",
  Premium = "premium",
  Family = "family",
  Team = "team",
  Business = "business",
}

export type TierInfo = {
    order: number
    type: Tier
    name: string
    description: string   
    priceMonthly: number
    priceAnnual: number
    minSeats: number
    maxSeats: number
    orgVaults: number
    orgGroups: number
    orgStorage: number
    features: string[]
    disabledFeatures: string[]
}

export const Tiers: Record<Tier, TierInfo> = {
  [Tier.Free]: {
      order: 0,
      type: Tier.Free,
      name: "免费版",
      description: "满足您基本的密码管理需求。",    
      priceMonthly: 0,
      priceAnnual: 0,
      minSeats: 1,
      maxSeats: 1,
      orgVaults: 0,
      orgGroups: 0,
      orgStorage: 0,
      features: ["无限密码条目", "无限设备"],
      disabledFeatures: [
          "多因素认证",
          "共享密码本",
          "加密文件存储",
          "安全报告",
          "内置验证器/一次性密码",
          "支持Markdown的富文本笔记",
          "从历史记录恢复密码条目",
      ],
  },
  [Tier.Premium]: {
      order: 1,
      type: Tier.Premium,
      name: "高级版",
      description: "高级多因素认证、加密文件存储等更多功能！",
      priceMonthly: 300, // ¥3.00
      priceAnnual: 3000, // ¥30.00               
      minSeats: 1,
      maxSeats: 1,
      orgVaults: 0,
      orgGroups: 0,
      orgStorage: 0,
      features: [
          "无限密码条目",
          "无限设备",
          "多因素认证",
          "1GB个人加密文件存储",
          "安全报告",
          "内置验证器/一次性密码",
          "支持Markdown的富文本笔记",
          "从历史记录恢复密码条目",
      ],
      disabledFeatures: ["共享密码本"],
  },
  [Tier.Family]: {
      order: 2,
      type: Tier.Family,
      name: "家庭版",
      description: "为全家人提供简单直观的密码管理和文件存储，可创建一个组织添加5个成员，共享高级别功能。",
      priceMonthly: 200, // ¥2.00
      priceAnnual: 2000, // ¥20.00            
      minSeats: 5,
      maxSeats: 10,
      orgVaults: 5,
      orgGroups: 0,
      orgStorage: 1000,

      features: [
          "无限密码条目",
          "无限设备",
          "多因素认证",
          "1GB个人加密文件存储",
          "1GB组织加密文件存储",
          "安全报告",
          "内置验证器/一次性密码",
          "支持Markdown的富文本笔记",
          "从历史记录恢复密码条目",
          "至少 5个/最多10个组织成员席位",
          "最多5个共享密码本",
          "最多1个用户组",
      ],
      disabledFeatures: [],
  },
  [Tier.Team]: {
      order: 3,
      type: Tier.Team,
      name: "团队版",
      description: "为您的团队提供强大的协作密码管理。",
      priceMonthly: 500, // ¥5.00 per user
      priceAnnual: 5000, // ¥50.00 per user
      minSeats: 2,
      maxSeats: 50,
      orgVaults: 20,
      orgGroups: 10,
      orgStorage: 5000,
      features: [
          "无限密码条目",
          "无限设备",
          "多因素认证",
          "1GB个人加密文件存储",
          "5GB组织加密文件存储",
          "安全报告",
          "内置验证器/一次性密码",
          "支持Markdown的富文本笔记",
          "从历史记录恢复密码条目",
          "至少2个/最多50个组织成员席位",
          "最多20个共享密码本",
          "最多10个用户组",
      ],
      disabledFeatures: [],
  },
  [Tier.Business]: {
      order: 4,
      type: Tier.Business,
      name: "企业版",
      description: "为您的企业提供一流的在线保护。",
      priceMonthly: 1000, // ¥10.00 per user
      priceAnnual: 10000, // ¥100.00 per user
      minSeats: 10,
      maxSeats: 200,
      orgVaults: -1,
      orgGroups: -1,
      orgStorage: 20000,
      features: [
          "无限密码条目",
          "无限设备",
          "多因素认证",
          "1GB个人加密文件存储",
          "20GB组织加密文件存储",
          "安全报告",
          "内置验证器/一次性密码",
          "支持Markdown的富文本笔记",
          "从历史记录恢复密码条目",
          "至少10个/最多200个组织成员席位",
          "无限密码本",
          "无限用户组",
          "目录同步/自动配置",
      ],
      disabledFeatures: [],
  },
};

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Trialing = "trialing",
  Unpaid = "unpaid"
}

export enum BillingType {
    OneTime = "one_time",
    Recurring = "recurring",
}

export enum BillingCycle {
    Monthly = "monthly",
    Annual = "annual"
}

export class Subscription extends Storable {
  constructor(init: Partial<Subscription> = {}) {
      super();
      Object.assign(this, init);
  }

  get id() {
      return this.accountId;
  }

  accountId: string = "";
  tier: Tier = Tier.Free;
  price: number = 0;
  seats: number = 1;
  billingType: BillingType = BillingType.OneTime;
  billingCycle: BillingCycle = BillingCycle.Monthly;
  paymentMethod: PaymentMethod = PaymentMethod.Alipay;
  status: SubscriptionStatus = SubscriptionStatus.Active;
  created: number = Date.now();
  expires: number = Date.now() + 100 * 365 * 24 * 60 * 60 * 1000;
}

export class SubscriptionInfo extends Subscription {
  daysRemaining: number = 0;
}
